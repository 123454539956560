
$heading-font-color: #fff;
$heading-bg: #df8244;
$row-bg-owner-ship-rate: #f0cbb0;
$row-bg-usufructuary-rate: #f7e5d7;

.dismemberment-table__item {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ececec;
  border-radius: 10px;

  &--selected {
    background-color: #f7f7f7;
    border-color: #1D1D1D;
  }
}

.table--dismemberment-table {
  border-collapse: collapse;

  th, td {
    padding: 10px;
    border: 1px solid #1D1D1D;
  }

  .table__header {
    color: $heading-font-color;
    background-color: $heading-bg;
  }
  .table__row--bareOwnershipRate {
    background-color: $row-bg-owner-ship-rate;
    border-bottom: 1px solid #1D1D1D;
  }
  .table__row--usufructuaryRate {
    background-color: $row-bg-usufructuary-rate;
    border-bottom: 1px solid #1D1D1D;
  }
}
